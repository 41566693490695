import React from "react"
import PropTypes from "prop-types"
import { LazyLoadImage } from "react-lazy-load-image-component"
import "./img-detail.scss"
import "react-lazy-load-image-component/src/effects/blur.css"

const ImgDetail = props => {
  return (
    <div className="container">
      <div className="row">
        <div className="d-flex w-100 justify-content-center col-xs-12">
          <LazyLoadImage
            effect="blur"
            height={props.height}
            width={props.width}
            className="img-fluid img-detail"
            placeholderSrc="blur"
            src={`../../${props.folder}/${props.path}`}
          ></LazyLoadImage>
        </div>
        <div
          style={{ borderBottom: "1px solid black", textAlign: "center" }}
          className="col d-flex align-items-center text-align-center justify-content-center py-3 text-img col-xs-12 w-100"
        >
          <span className="px-3">
            {props.description} – Progetto di Arch. Andrè Benaim
            {props.ph ? ` - Foto di ${props.ph}` : ""}
          </span>
        </div>
      </div>
    </div>
  )
}
ImgDetail.propTypes = {
  folder: PropTypes.node.isRequired,
  path: PropTypes.node.isRequired,
}
export default ImgDetail
