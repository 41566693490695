import React from "react"
import PropTypes from "prop-types"
import Header from "../header/header"
import Footer from "../footer/footer"
import "../layout/layout.scss"

const LayoutPortfolio = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container animated-layout">
        <main>{children}</main>
        <div className="p-2">
          <Footer />
        </div>
      </div>
    </>
  )
}

LayoutPortfolio.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LayoutPortfolio
