import React from "react"
import Seo from "../shared/seo/seo"
import LayoutPortfolio from "../shared/layout-portfolio/layout-portfolio"
import ImgDetail from "../shared/img-detail/img-detail"
import { graphql, useStaticQuery } from "gatsby"

const ShopsRestaurants = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          projects {
            shops_restaurants {
              title
            }
          }
        }
      }
    }
  `)
  return (
    <LayoutPortfolio>
      <Seo
        title={data.site.siteMetadata.projects.shops_restaurants.title}
      ></Seo>
      <div className="container">
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              description="TAVOLINI IN VETRO CON BORDO SAGOMATO IN ROVERE"
              path="rsz_fb__1350.jpg"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              description="BANCONE BAR IN VETRO CURVO, ARREDI E SCALA IN ROVERE"
              path="rsz_1fb__1335.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              ph="Federico Buscarino"
              description="BANCONE BAR E BOISERIE IN IROKO INTRECCIATO"
              path="rsz__mg_9953.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              description="BANCONE, PARETI E TAVOLI IN ROVERE DI RECUPERO"
              path="rsz_fuori_porta_5.png"
            ></ImgDetail>
          </div>
        </div>
        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              description="BANCONE BAR IN ACCIAIO ED ELEMENTI IN LEGNO DI RECUPERO DA VECCHI BARRIQUES"
              path="rsz_cf019161.png"
            ></ImgDetail>
          </div>
        </div>

        <div className="row ">
          <div className="col mt-4">
            <ImgDetail
              folder="shop-restaurant"
              description="PORTA CURVA E ARREDI IN CILIEGIO, PARETI RIVESTITE IN TESSUTO"
              path="rsz_2.png"
            ></ImgDetail>
          </div>
        </div>
      </div>
    </LayoutPortfolio>
  )
}

export default ShopsRestaurants
